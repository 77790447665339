<template>
  <ConfirmPopup></ConfirmPopup>

  <div class="container">
    <h4 class="text-center my-4">Список договоров ППС</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">


      <DataTable :value="teachersContracts_form.contracts" showGridlines stripedRows
                 :globalFilterFields="['lastname','firstname']"
                 v-model:filters="filters"
                 :paginator="true"
                 :rows="20"
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[5,10,20,30,40,50]"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
        <template #header>
          <div class="p-d-flex p-jc-between p-ai-center">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search"/>
                            <InputText v-model="filters['global'].value" placeholder="Поиск"/>
                        </span>
          </div>
        </template>
        <Column header="№" :style="{width: '60px'}">
          <template #body="{index}">
            {{ index + 1 }}
          </template>
        </Column>

        <Column field="contract_number" header="Номер договора"></Column>
        <Column field="contract_type_name" header="Тип договора"></Column>
        <Column header="Статус">
          <template #body="{data}">
            <div v-if="data.close_end == 1">
              <p>Завершается</p>
              <p v-if="data.is_send == 1">Отправлено на почту</p>
              <p v-else>Не отправлено на почту</p>
            </div>
            <p v-else>Действует</p>
          </template>
        </Column>
        <!--                <Column field="date" header="Дата заключения"></Column>-->
        <Column header="Дата начала работы">
          <template #body="{data}">
            {{ convertTimestampToDate(data.contract_start_date) }}
          </template>
        </Column>
        <Column header="Дата начала работы">
          <template #body="{data}">
            {{ convertTimestampToDate(data.contract_start_date) }}
          </template>
        </Column>
        <Column header="Дата завершения">
          <template #body="{data}">
            {{ convertTimestampToDate(data.contract_end_date) }}
          </template>
        </Column>
        <Column header="ФИО исполнителя">
          <template #body="{data}">
            {{ data.lastname }} {{ data.firstname }}
          </template>
        </Column>
        <Column header="Действия" class="text-center">
          <template #body="{data}">
            <a href="#">Зарегистрировать</a>
            <!--            <a :href="'https://back.uib.kz/documents_uploads/'+ data.file_url" v-if="data.file_url">Печатать</a>-->

            <Button label="Печатать" @click="openModalCreateContract(data)"></Button>

            <!--                        <Button icon="pi pi-file-pdf" label="Печать" class="p-button-rounded m-1" @click="printContract(data.id)"/>-->
            <div v-if="data.contract_type != 3 && data.contract_type != 4">
              <Button icon="pi pi-file" label="Доп. соглашение" class="p-button-rounded m-1"
                      @click="openModalCreateAdditional(data)"/>
              <!--              <a v-else :href="'https://back.uib.kz/documents_uploads/'+ data.file_url">Доп. соглашение</a>-->
            </div>
            <!--                        <Button icon="pi pi-trash" label="Расторгнуть" class="p-button-rounded p-button-danger m-1" @click="deleteFromActiveDirectory(data.id)"/>-->
            <!--                        <Button label="АВР" class="p-button-rounded m-1" @click="ads(data.id)"/>-->
          </template>
        </Column>


      </DataTable>

      <!--            <Paginator :rows="teacher.pageSize" :totalRecords="teacher.pageCount" :alwaysShow="true"-->
      <!--                       :first="(page*teacher.pageSize)-teacher.pageSize"-->
      <!--                       :pageLinkSize="5"-->
      <!--                       @page="onPage($event)">-->
      <!--            </Paginator>-->

    </div>

    <Dialog v-model:visible="modal_create_contract" :style="{width: '25vw'}">
      <template #header>Создание контракта {{ selectedContract.lastname }} {{ selectedContract.firstname }}</template>

      <div class="row align-items-center">
        <div>
          <label class="col-md-3">Выберите тип</label>
          <Dropdown class="col-md-9" v-model="selectedContract.contract_type"
                    :options="contractTemplates.teachers_contract_types"
                    optionLabel="name" optionValue="id"></Dropdown>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Выберите срок</label>
          <Dropdown class="col-md-9" v-model="selectedContract.contract_term" :options="contractTerms"
                    optionLabel="name" optionValue="term"></Dropdown>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Выберите форму</label>
          <Dropdown class="col-md-9" v-model="selectedContract.template_id" optionLabel="name"
                    optionValue="id" :options="contractTemplates.contract_templates"></Dropdown>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Дата трудового договора</label>
          <Calendar class="col-md-9" v-model="work_start_date" dateFormat="yy-mm-dd" :showWeek="true"></Calendar>
        </div>

        <div class="mt-3">
          <label class="col-md-3">С даты</label>
          <Calendar class="col-md-9" v-model="contract_start_date" dateFormat="yy-mm-dd" :showWeek="true"></Calendar>
        </div>

        <div class="mt-3">
          <label class="col-md-3">До даты</label>
          <Calendar class="col-md-9" v-model="contract_end_date" dateFormat="yy-mm-dd" :showWeek="true"></Calendar>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Должность</label>
          <Dropdown class="col-md-9" v-model="selectedContract.teaching_position_id" optionLabel="name" optionValue="id"
                    :options="contractTemplates.teaching_position"></Dropdown>
        </div>
      </div>

      <template #footer>
        <Button label="Создать контракт" @click="createContract"></Button>
      </template>
    </Dialog>

    <Dialog v-model:visible="modal_info_contract" header="Информация о печати" :style="{width: '25vw'}">
      <div class="row align-items-center">
        <div class="mt-3">
          Номер документа: {{ selectedContract.contract_number }}
        </div>
        <div class="mt-3">
          ФИО: {{ selectedContract.lastname }} {{ selectedContract.firstname }}
        </div>
        <div class="mt-3">
          Тип договора: {{ selectedContract.contract_type_name }}
        </div>
        <div class="mt-3">
          Дата трудового документа: {{ convertTimestampToDate(selectedContract.work_start_date) }}
        </div>
        <div class="mt-3">
          С даты: {{ convertTimestampToDate(selectedContract.contract_start_date) }}
        </div>
        <div class="mt-3">
          До даты: {{ convertTimestampToDate(selectedContract.contract_end_date) }}
        </div>
      </div>
      <template #footer>
        <a :href="'https://back.uib.kz/documents_uploads/'+ selectedContract.file_url">
          <Button label="Распечатать"></Button>
        </a>
      </template>
    </Dialog>

    <Dialog v-model:visible="modal_info_additional" header="Информация о доп соглашении" :style="{width: '25vw'}">
      <div class="row align-items-center">
        <div class="mt-3">
          Номер документа: {{ additionalAgreement.contract_number }}
        </div>
        <div class="mt-3">
          Дата трудового документа: {{ convertTimestampToDate(additionalAgreement.work_start_date) }}
        </div>
        <div class="mt-3">
          С даты: {{ convertTimestampToDate(additionalAgreement.contract_start_date) }}
        </div>
        <div class="mt-3">
          До даты: {{ convertTimestampToDate(additionalAgreement.contract_end_date) }}
        </div>
      </div>
      <template #footer>
        <a :href="'https://back.uib.kz/documents_uploads/'+ additionalAgreement.file_url">
          <Button label="Распечатать"></Button>
        </a>
      </template>
    </Dialog>

    <Dialog v-model:visible="modal_create_additional" :style="{width: '25vw'}">
      <template #header>Создание доп соглашения {{ selectedContract.lastname }} {{
          selectedContract.firstname
        }}
      </template>

      <div class="row align-items-center">
        <div>
          <label class="col-md-3">Выберите тип</label>
          <Dropdown class="col-md-9" v-model="selectedContract.contract_type"
                    :options="contractTemplates.teachers_contract_types"
                    optionLabel="name" optionValue="id"></Dropdown>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Выберите срок</label>
          <Dropdown class="col-md-9" v-model="selectedContract.contract_term" :options="contractTerms"
                    optionLabel="name" optionValue="term"></Dropdown>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Выберите форму</label>
          <Dropdown class="col-md-9" v-model="selectedContract.template_id" optionLabel="name"
                    optionValue="id" :options="contractTemplates.contract_templates"></Dropdown>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Дата трудового договора</label>
          <Calendar class="col-md-9" v-model="work_start_date" dateFormat="yy-mm-dd" :showWeek="true"></Calendar>
        </div>

        <div class="mt-3">
          <label class="col-md-3">С даты</label>
          <Calendar class="col-md-9" v-model="contract_start_date" dateFormat="yy-mm-dd" :showWeek="true"></Calendar>
        </div>

        <div class="mt-3">
          <label class="col-md-3">До даты</label>
          <Calendar class="col-md-9" v-model="contract_end_date" dateFormat="yy-mm-dd" :showWeek="true"></Calendar>
        </div>

        <div class="mt-3">
          <label class="col-md-3">Должность</label>
          <Dropdown class="col-md-9" v-model="selectedContract.teaching_position_id" optionLabel="name" optionValue="id"
                    :options="contractTemplates.teaching_position"></Dropdown>
        </div>
      </div>

      <template #footer>
        <Button v-if="selectedContract.template_id != 2" label="Создать контракт" @click="createAdditional"></Button>
        <Button v-else label="Нужно предоставить шаблон для 2023 года!" disabled></Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import {FilterMatchMode, FilterOperator} from 'primevue/api'
import {convertTimestampToDate} from "@/utils/helpers/date.helpers";

export default {
  name: 'TeachersContracts',
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      },
      btnSaveDisabled: false,
      modal_create_contract: false,
      modal_info_contract: false,
      modal_create_additional: false,
      modal_info_additional: false,
      selectedContract: null,

      work_start_date: null,
      contract_start_date: null,
      contract_end_date: null,

      contractTerms: [
        {term: '1', name: '1 год'},
        {term: "2", name: '2 года'},
        {term: '3', name: '3 года'},
        {term: '4', name: '1 семестр'}
      ],
    }
  },
  computed: {
    ...mapState('teachersContracts', ['teachersContracts_form', 'additionalAgreement', 'contractTemplates']),
  },

  watch: {
    work_start_date: function (newValue, oldValue) {
      this.selectedContract.work_start_date = Date.parse(newValue) / 1000
    },
    contract_start_date: function (newValue, oldValue) {
      this.selectedContract.contract_start_date = Date.parse(newValue) / 1000
    },
    contract_end_date: function (newValue, oldValue) {
      this.selectedContract.contract_end_date = Date.parse(newValue) / 1000
    },

    modal_create_contract: function (newValue, oldValue) {
      if (!newValue) {
        this.selectedContract = null;
      }
    },
    modal_create_additional: function (newValue, oldValue) {
      if (!newValue) {
        this.selectedContract = null;
      }
    }
  },

  methods: {
    ...mapActions('teachersContracts', ['GET_TEACHERS_CONTRACTS', 'GET_ADDITIONAL_AGREEMENT', 'GET_CONTRACT_TEMPLATES']),
    ...mapActions('kkk', ['POST_CREATE_CONTRACT', 'POST_UPDATE_CONTRACT']),
    //...mapMutations('userinfo', ['SET_USER_ID']),

    async createAdditional() {
      this.selectedContract.additional_contract_status = null;
      this.selectedContract.additional_contract_status2 = null;
      this.selectedContract.additional_contract_status3 = null;

      this.selectedContract.contract_type == 1 ? this.selectedContract[`additional_contract_status`] = 1 :
        (this.selectedContract.contract_type == 2 ? this.selectedContract[`additional_contract_status2`] = 1 :
          this.selectedContract.contract_type == 5 ? this.selectedContract[`additional_contract_status3`] = 1 : 'Ошибка')

      if (await this.POST_CREATE_CONTRACT(this.selectedContract)) {
        this.$message({title: 'Успешно!'})
        await this.GET_TEACHERS_CONTRACTS()
        await this.GET_ADDITIONAL_AGREEMENT(this.selectedContract.parent_id)
        this.selectedContract = null;
        this.modal_create_additional = false;
      }
    },

    async createContract() {
      if (await this.POST_UPDATE_CONTRACT(this.selectedContract)) {
        this.$message({title: 'Успешно!'})
        await this.GET_TEACHERS_CONTRACTS()
        this.selectedContract = null;
        this.modal_create_contract = false;
      }
    },

    async openModalCreateAdditional(data) {
      if (await this.GET_ADDITIONAL_AGREEMENT(data.id)) {
        this.modal_info_additional = true;
      } else {
        this.selectedContract = {...data};
        this.selectedContract.template_id = null;
        this.selectedContract.parent_id = data.id;
        this.work_start_date = new Date(data.work_start_date * 1000);
        this.contract_start_date = new Date(data.contract_start_date * 1000);
        this.contract_end_date = new Date(data.contract_end_date * 1000);
        this.modal_create_additional = true;
      }
    },

    openModalCreateContract(data) {
      this.selectedContract = {...data};

      if (data.file_url) {
        this.modal_info_contract = true;
      } else {
        this.modal_create_contract = true;
        this.selectedContract.template_id = null;

        this.work_start_date = new Date(data.work_start_date * 1000);
        this.contract_start_date = new Date(data.contract_start_date * 1000);
        this.contract_end_date = new Date(data.contract_end_date * 1000);
      }
    },

    convertTimestampToDate,
    confirmAdditional(event, id) {
      let contract = this.teachersContracts_form.contracts.find(item => item.id == id)
      let postData = {
        id: contract.id,
        contract_number: contract.contract_number,
        work_start_date: contract.work_start_date,
        contract_start_date: contract.contract_start_date,
        contract_end_date: contract.contract_end_date,
        user_id: contract.user_id,
        contract_type: contract.contract_type,
        file_url: contract.file_url,
        status: contract.status,
        department_id: contract.department_id,
        contract_term: contract.contract_term,
        teaching_position_id: contract.teaching_position_id,
        academic_year_id: contract.academic_year_id,
        pps_order_id: contract.pps_order_id,
        close_end: contract.close_end,
        is_send: contract.is_send,
        sended_date: contract.sended_date,
        additional_contract_status: null,
        additional_contract_status2: null,
        additional_contract_status3: null
      }
      contract.contract_type == 1 ? postData[`additional_contract_status`] = 1 : (contract.contract_type == 2 ? postData[`additional_contract_status2`] = 1 : contract.contract_type == 5 ? postData[`additional_contract_status3`] = 1 : 'Ошибка')


      this.$confirm.require({
        target: event.currentTarget,
        message: 'Вы уверены что хотите создать дополнительное соглашение?',
        icon: 'pi pi-question-circle',
        acceptIcon: 'pi pi-check',
        rejectIcon: 'pi pi-times',
        rejectButton: 'text-danger',
        accept: () => {
          let postRes = this.POST_CREATE_CONTRACT(postData)
          postRes
            ? this.$toast.add({
              severity: 'info',
              summary: 'Доп. соглашение',
              detail: 'Вы успешно создали дополнительное соглашение!',
              life: 1500
            })
            : this.$toast.add({
              severity: 'error',
              summary: 'Доп. соглашение',
              detail: 'Что то пошло не так!',
              life: 1500
            });
        }
      });
    }
  },
  async mounted() {

    await this.GET_TEACHERS_CONTRACTS()
    await this.GET_CONTRACT_TEMPLATES()
    this.loading = false
  },
}

</script>
<script setup>
</script>